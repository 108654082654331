@import "../../scss/variables.scss";

.button {
  @include flexCenter;
  gap: 20px;
  height: 64px;
  border-radius: 26px;
  color: $bgcolor;
  font-size: 20px;
  background-color: $redButtons;
  border: none;
  padding: 0 10px;
  box-sizing: border-box;
  width: 380px;

  &:hover {
    cursor: pointer;
  }

  svg {
    scale: 0.8;
  }
}

.main {
  position: relative;
  background-color: $color;
  color: $bgcolor;
  max-width: 800px;
  font-size: 20px;
  border-radius: 35px;
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow: hidden;

  p {
    display: inline;
    color: $redButtons;
  }

  span {
    background-color: $bgcolor;
    color: $color;
    padding: 8px 15px;
    border-radius: 25px;
    white-space: nowrap;
  }
  .title {
    display: flex;
    justify-content: center;
    gap: 26px;
  }

  .jspmMessage {
    font-family: $ff;
    font-size: 26px;
    letter-spacing: 0.1em;
    text-align: center;
  }

  .background {
    position: absolute;
    @include flexCenter;
    flex-direction: column;
    gap: 20px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: $bgcolor;
  }
}

@media (max-height: 800px) {
  .button {
    gap: 20px;
    height: 54px;
    border-radius: 20px;
    font-size: 20px;
    width: 340px;

    svg {
      scale: 0.6;
    }
  }

  .main {
    max-width: 600px;
    font-size: 16px;
    border-radius: 25px;
    padding: 25px 20px;

    .title {
      display: flex;
      justify-content: center;
      gap: 22px;
    }

    .jspmMessage {
      font-size: 20px;
    }
  }
}
