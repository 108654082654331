@import "../scss/variables.scss";

.main {
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: auto;

  .redButton {
    background-color: $redButtons;
    color: $bgcolor;
    height: 51px;
    border-radius: 20px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    text-wrap: nowrap;

    svg {
      margin-right: 20px;
    }

    span {
      color: $bgcolor;
    }

    // select {
    //   height: 36px;
    //   border-radius: 11px;
    //   padding: 0 10px;
    //   box-sizing: border-box;
    //   border: none;
    //   margin: 0 10px;
    //   font-size: 18px;
    //   color: $color;
    // }

    .ascDesc {
      background-color: $bgcolor;
      min-width: 26px;
      height: 38px;
      border-radius: 11px;

      div {
        &:hover {
          cursor: pointer;
        }
      }

      span {
        position: absolute;
        display: block;
        width: 16px;
        height: 4px;
        border-radius: 2px;
        background-color: gray;
        top: 8px;
      }
      .asc {
        position: relative;
        height: 19px;
        .one {
          rotate: 45deg;
          left: 10px;
        }
        .two {
          rotate: -45deg;
          left: 1px;
        }
      }

      .desc {
        position: relative;
        height: 19px;

        .one {
          rotate: -45deg;
          left: 10px;
        }
        .two {
          rotate: 45deg;
          left: 1px;
        }
      }
      .activeSort {
        background-color: $color;
      }
    }
  }

  .Btn {
    cursor: pointer;

    &:active {
      scale: 0.97;
    }
  }

  @media (max-width: 1510px) {
    .redButton {
      font-size: 20px;

      svg {
        scale: 0.9;
      }
      select {
        font-size: 16px;
      }
    }
  }
}

@media (max-height: 800px) {
  .main {
    min-height: 36px;
    gap: 10px;

    .redButton {
      height: 36px;
      border-radius: 14px;
      font-size: 18px;
      padding: 0 10px;

      svg {
        margin-right: 6px;
        scale: 0.6;
      }
    }
  }
}
