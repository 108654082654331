@import "../scss/variables.scss";

.ascDesc {
  position: absolute;
  right: 6px;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  .line {
    position: absolute;
    display: inline-block;
    width: 10px;
    height: 3px;
    border-radius: 2px;
    background-color: #a0b7d6;
    top: 4px;
  }

  .asc {
    position: relative;
    height: 12px;
    width: 14px;
    .one {
      rotate: 45deg;
      left: 6px;
    }
    .two {
      rotate: -45deg;
      left: 0px;
    }
  }

  .desc {
    position: relative;
    height: 12px;
    width: 14px;

    .one {
      rotate: -45deg;
      left: 6px;
    }
    .two {
      rotate: 45deg;
      left: 0;
    }
  }
  .activeSort {
    background-color: $color;
  }
}
