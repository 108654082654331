@import "../scss/variables.scss";
@import "../scss/loader.scss";

@mixin btnParams {
  color: $color;
  background-color: $bgcolor;
  width: 232px;
  height: 56px;
  margin-top: 20px;
  border-radius: 28px;
}

.main {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 42px;

  .logoBox {
    width: 700px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  form {
    position: relative;
    background-color: $color;
    width: 420px;
    height: 450px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-top: 30px;
    box-sizing: border-box;

    label {
      color: $bgcolor;
      display: block;
      font-weight: 300;
      font-size: 22px;
      letter-spacing: 0.3em;
      text-align: center;

      .input {
        font-size: 24px;
        width: 360px;
        height: 60px;
        margin-top: 5px;
        box-sizing: border-box;
        padding-left: 20px;
        color: black;
        text-align: start;
        border-radius: 35px;
        border: none;

        &:focus {
          outline: none;
        }
      }

      .inputWrong {
        border: 3px red solid;
      }
    }

    .title {
      font-size: 30px;
      text-align: center;
      color: $bgcolor;
      letter-spacing: 0.25em;
      padding-left: 8px;
    }

    .button {
      @include btnParams;
      border: none;
      letter-spacing: 0.3em;
      font-size: 24px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
      }

      &:active {
        scale: 0.97;
      }
    }

    .disableLoaderBtn {
      @include btnParams;
      @include flexCenter;
    }

    .wrongAuth {
      color: $wrongMessageColor;
      -webkit-text-stroke: 1px $wrongMessageStorkeColor;
      -moz-osx-text-stroke: 1px $wrongMessageStorkeColor;
      position: absolute;
      font-size: 20px;
      top: 325px;
      align-items: center;
      letter-spacing: 0.08em;
    }
  }
}

@media (max-height: 800px) {
  .main {
    gap: 20px;

    .logoBox {
      width: 560px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    form {
      width: 420px;
      height: 360px;
      border-radius: 20px;
      gap: 20px;
      padding-top: 20px;

      label {
        font-size: 18px;

        .input {
          font-size: 20px;
          width: 360px;
          height: 50px;
        }
      }

      .title {
        font-size: 26px;
      }

      .button {
        width: 200px;
        height: 46px;
        margin-top: 16px;
        border-radius: 20px;
        font-size: 20px;
      }

      .disableLoaderBtn {
        width: 200px;
        height: 46px;
        margin-top: 16px;
        border-radius: 20px;
      }

      .wrongAuth {
        font-size: 20px;
        top: 325px;
      }
    }
  }
}
