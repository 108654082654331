$lineHeight: 2.8mm;

.card {
  width: 39mm;
  height: 58mm;
  font-size: 8px;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: black;
  display: flex;
  flex-direction: column;

  div {
    min-height: $lineHeight;
    overflow: hidden;
    line-height: 2.5mm;
  }

  .head {
    display: flex;
    min-height: 14.7mm;
    max-height: 14.7mm;

    .dataBox {
      min-width: 14mm;
      min-height: 14mm;
      max-width: 14mm;
      max-height: 14mm;
      margin-right: 1mm;
      display: flex;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .code {
    text-align: center;
    height: 2.3mm;
  }

  .barBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .core {
    display: flex;
    flex-direction: column;

    .oneLine {
      min-height: $lineHeight;
    }

    .twoLine {
      min-height: calc($lineHeight * 2);
    }

    .threeLine {
      min-height: calc($lineHeight * 3);
    }

    .compAndSert {
      display: flex;
      justify-content: space-between;

      .eacBox {
        min-width: calc($lineHeight * 1.8);
        min-height: calc($lineHeight * 1.8);
        max-width: calc($lineHeight * 1.8);
        max-height: calc($lineHeight * 1.8);
        overflow: hidden;

        img {
          width: 100%;
        }
      }
    }
  }
}
