@import "../scss/variables.scss";
@import "../scss/loader.scss";

.loaderBox {
  margin-top: 200px;
  color: $redButtons;
  font-size: $fsDefault;
}

.main {
  position: relative;
  background-color: $color;
  max-width: 100%;
  height: 98%;
  border-radius: 25px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: space-between;

  .infoOrderBox {
    position: absolute;
    width: calc(100% - 40px);
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      background-color: white;
      color: $color;
      font-size: 24px;
      height: 46px;
      width: 280px;
      max-width: 350px;
      border-radius: 23px;
      padding: 5px 20px;
      @include flexCenter;
      box-sizing: border-box;
      font-weight: 600;
      text-wrap: nowrap;
      overflow: hidden;
    }
  }

  .data {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;

    .dataTitle {
      background-color: $bgcolor;
      height: 60px;
      width: 100%;
      border-radius: 10px;
      display: grid;
      align-items: center;
      gap: 7px;
      box-sizing: border-box;
      grid-template-columns: $gridLabels;
    }

    .dataUnaccTitle {
      grid-template-columns: $gridUnaccLabels;
    }

    .inputBox {
      @include flexCenter;
      min-width: 30px;
      position: relative;

      input {
        border: none;
        font-family: $ff;
        height: 30px;
        min-width: 20px;
        color: $redButtons;
        font-size: $fsDefault;
        font-weight: 600;
        text-align: center;
        margin: 0 2px;
        padding: 0;
        background-color: transparent;

        &::placeholder {
          color: $color;
        }
        &::-moz-placeholder {
          color: $color;
          opacity: 1;
        }

        &:focus {
          outline: none;
        }

        .inputActive {
          color: $redButtons;
        }
      }
    }
    .dataBox {
      background-color: $bgcolor;
      max-width: 100%;
      max-height: 73vh;
      border-radius: 10px;
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: rgb(109, 109, 109) rgb(233, 233, 233);

      &::-webkit-scrollbar-button {
        display: none;
      }
    }
  }

  @media (max-width: $widthSize) {
    .infoOrderBox {
      .info {
        font-size: 22px;
        width: 230px;
        max-width: 250px;
      }
    }
    .data {
      .dataTitle {
        height: 45px;
        .inputBox {
          input {
            font-size: $fsSm;
            translate: -5px 0;
          }
        }
      }
      .dataBox {
        max-height: 70vh !important;
      }
      span {
        font-size: $fsSm;
      }
    }
  }
  @media (max-height: 1000px) {
    .dataBox {
      max-height: 68vh !important;
    }
  }
}

@media (max-height: 800px) {
  .main {
    border-radius: 20px;
    padding: 10px;
    gap: 10px;

    .infoOrderBox {
      width: calc(100% - 30px);
      height: 40px;

      .info {
        font-size: 20px;
        height: 36px;
        width: 220px;
        max-width: 320px;
      }
    }

    .data {
      gap: 5px;

      span {
        font-size: 16px;
      }

      .dataTitle {
        height: 36px;
        align-items: center;
        gap: 7px;
      }

      .inputBox {
        input {
          font-size: 16px;
        }
      }
    }
  }
}
