@import "../../scss/variables.scss";

.main {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 160px;
  background-color: rgba(0, 0, 0, 0.7);

  .window {
    position: relative;
    background-color: $color;
    border-radius: 25px;
    padding: 35px 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .title {
      font-weight: 900;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 40px;
      @include flexCenter;
      gap: 10px;
    }

    .barcode {
      display: block;
      height: 50px;
      width: 300px;
      border-radius: 30px;
      background-color: white;
      font-size: 26px;
      font-family: $ff;
      text-align: center;
      color: $color;
      line-height: 50px;
      border: none;
    }

    .showArea {
      position: relative;
      @include flexCenter;
      width: 300px;
      aspect-ratio: 0.7;
      min-height: 200px;
      background-color: white;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 15px;
      overflow: hidden;

      .imageLabel {
        width: 150%;
        height: 70%;
        transform: rotate(-90deg);
      }

      .content {
        width: 39mm;
        height: 58mm;
        transform: rotate(90deg);
      }

      .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: white;
      }
    }

    .close {
      background-color: $bgcolor;
      position: absolute;
      width: 34px;
      height: 34px;
      border-radius: 21px;
      top: 14px;
      right: 14px;
      cursor: pointer;

      span {
        background-color: $color !important;
        position: absolute;
        display: block;
        width: 30px;
        height: 4px;
        border-radius: 2px !important;
        top: 15px;
        left: 2px;
        padding: 0 !important;
      }

      .one {
        rotate: 45deg;
      }

      .two {
        rotate: -45deg;
      }
    }
  }

  .warning {
    position: fixed;
    @include flexCenter;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 160px;
    background-color: rgba(0, 0, 0, 0.7);

    .warningWindow {
      font-family: $ff;
      font-size: 26px;
      color: $color;
      width: 850px;
      border-radius: 36px;
      position: absolute;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 10px 30px;
      box-sizing: border-box;
      box-shadow: 0 0 30px gray inset;

      div {
        text-align: center;
      }

      .title {
        font-size: 36px;
        gap: 20px;
        display: flex;
        align-items: center;
      }

      .barInfo {
        border: 1px solid $color;
        border-radius: 15px;
        height: 60px;
        width: 100%;
        line-height: 60px;
      }

      .btnBox {
        margin: 25px 0;
        display: flex;
        justify-content: center;
        gap: 20px;

        button {
          width: 140px;
          height: 60px;
          border: 3px solid $color;
          border-radius: 15px;
          font-size: 20px;
          font-family: $ff;

          &:hover {
            cursor: pointer;
          }
        }

        .confirm {
          color: white;
          background-color: $color;
        }

        .cancel {
          background-color: white;
          color: $color;
        }
      }
    }
  }
}

@media (max-height: 800px) {
  .main {
    padding-left: 130px;

    .window {
      border-radius: 25px;
      padding: 25px 40px;
      gap: 15px;

      .title {
        font-size: 30px;
        gap: 10px;
      }

      .barcode {
        height: 40px;
        width: 260px;
        border-radius: 30px;
        font-size: 22px;
        line-height: 50px;
      }

      .showArea {
        width: 260px;
      }

      .close {
        width: 28px;
        height: 28px;

        span {
          width: 22px;
          top: 12px;
          left: 3px;
        }
      }
    }

    .warning {
      .warningWindow {
        font-size: 22px;
        width: 650px;
        border-radius: 26px;

        .title {
          font-size: 32px;
        }

        .barInfo {
          height: 50%;
        }

        .btnBox {
          button {
            height: 50px;
          }
        }
      }
    }
  }
}
