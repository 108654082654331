@import "./scss/variables.scss";

.app {
  height: 100%;

  .main {
    height: 100%;
    display: flex;

    .mainContent {
      position: relative;
      @include flexCenter;
      height: 100%;
      box-sizing: border-box;
      margin: 0 20px;
      font-family: $ff;
      flex-grow: 1;

      .accessLevel {
        font-size: 24px;
        color: $redButtons;
        margin-top: 200px;
      }
    }
  }
}
