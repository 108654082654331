@import "../../scss/variables.scss";

.dataTitle {
  background-color: $bgcolor;
  height: 60px;
  width: 100%;
  border-radius: 10px;
  display: grid;
  grid-template-columns: $gridOrders;
  align-items: center;
  gap: 2px;
  box-sizing: border-box;
}

.order {
  height: 52px;

  span {
    @include flexCenter;
    width: 100%;
    height: 100%;
    color: $color;
    font-size: $fsDefault;
    font-weight: 500;
    margin: 0 auto;
    white-space: nowrap;
    border-right: 1px solid $color;
    overflow: hidden;
  }

  .processingStatus {
    color: #cd8820;
  }

  .finishedStatus {
    color: #04ae20;
  }

  .btnPanel {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 3px;
    margin-right: 3px;

    button {
      background-color: $color;
      border: 2px $color solid;
      height: 40px;
      aspect-ratio: 1.4;
      border-radius: 12px;
      cursor: pointer;
      @include flexCenter;

      &:active {
        scale: 0.97;
      }

      svg {
        scale: 0.8;
      }
    }

    .nonAccessButtons {
      background-color: $bgcolor;

      &:active {
        scale: 1;
      }
      &:hover {
        cursor: auto;
      }
    }

    .lastOrderButtons {
      background-color: $redButtons;
      border: none;
    }
  }
}

@media (max-height: 1000px) {
  .order {
    min-height: 47px;
    max-height: 47px;
  }
}

@media (max-height: 910px) {
  .order {
    min-height: 45px;
    max-height: 45px;
  }
}

@media (max-height: 850px) {
  .order {
    min-height: 38px;
    max-height: 38px;
  }
}

@media (max-width: $widthSize) {
  .dataTitle {
    grid-template-columns: 4fr 8fr 10fr 10fr 7fr 8fr 6fr 140px;
  }
  .order {
    span {
      font-size: $fsSm;
    }
    .btnPanel {
      gap: 1px;

      button {
        height: 35px;
        aspect-ratio: 1.05;
        width: 45px;
      }
    }
  }
}

@media (max-height: 800px) {
  .dataTitle {
    height: 48px;
    border-radius: 10px;
    gap: 2px;
  }

  .order {
    min-height: 30px;
    max-height: 30px;

    span {
      font-size: 16px;
    }

    .btnPanel {
      gap: 2px;
      margin-right: 2px;

      button {
        height: 28px;
        border-radius: 8px;
        aspect-ratio: 1.3;
      }
    }
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);

  .errorMessage {
    position: absolute;
    top: 25vh;
    left: 25vw;
    height: 100px;
    width: 300px;
    border-radius: 15px;
    background-color: $color;
    color: $bgcolor;
    @include flexCenter;
    font-size: $fsDefault;

    .close {
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: $bgcolor;
      top: 6px;
      right: 6px;
      cursor: pointer;

      span {
        position: absolute;
        display: block;
        width: 18px;
        height: 4px;
        border-radius: 2px;
        background-color: $color;
        top: 10px;
        left: 3px;
      }

      .one {
        rotate: 45deg;
      }

      .two {
        rotate: -45deg;
      }
    }
  }
}
