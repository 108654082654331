@import "../../scss/variables.scss";

.box {
  display: flex;
  align-items: center;
  gap: 10px;

  .label {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  select,
  input {
    width: 380px;
    height: 45px;
    border-radius: 25px;
    font-size: 20px;
    padding: 0 25px;
    box-sizing: border-box;
    color: $color;
    text-align: center;
    border: none;
    font-family: $ff;
  }

  input {
    width: 100px;
    padding: 0 0 0 25px;
  }
}

@media (max-height: 800px) {
  .box {
    select,
    input {
      width: 340px;
      height: 35px;
      border-radius: 25px;
      font-size: 16px;
      padding: 0 15px;
    }

    input {
      width: 100px;
      padding: 0 0 0 20px;
    }
  }
}
