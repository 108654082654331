@import "../../scss/variables.scss";
@import "../../scss/whiteLoader.scss";

.main {
  position: fixed;
  @include flexCenter;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding-left: 160px;
  z-index: 2;

  .window {
    background-color: $color;
    position: relative;
    padding: 10px 30px 30px 30px;
    box-sizing: border-box;
    border-radius: 25px;

    form {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      box-sizing: border-box;
      font-family: $ff;

      label {
        position: relative;

        .svgBox {
          position: absolute;
          @include flexCenter;
          height: 40px;
          width: 40px;
          left: 6px;
          top: 40px;
        }
      }

      .titleBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        translate: -15px 0;
        gap: 10px;

        .title {
          font-weight: 900;
          letter-spacing: 6px;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 40px;
        }

        svg {
          position: relative;
          translate: 0 5px;
        }
      }

      label {
        color: $bgcolor;
        display: block;
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 0.3em;
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
          font-size: $fsDefault;
          border: none;
        }

        .inputTitle {
          margin: 0 0 10px 0;
        }

        .input,
        select {
          width: 450px;
          height: 44px;
          margin-bottom: 15px;
          box-sizing: border-box;
          padding-left: 50px;
          border-radius: 15px;
          font-size: 20px;
          color: $color;

          &:focus {
            outline: none;
          }
        }
      }

      .inputWrong {
        border: 3px red solid;
      }

      .button {
        color: $bgcolor;
        background-color: $redButtons;
        width: 232px;
        height: 56px;
        margin-top: 20px;
        border-radius: 28px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;

        &:hover {
          cursor: pointer;
        }

        &:active {
          scale: 0.97;
        }
      }

      .close {
        background-color: $bgcolor;
        position: absolute;
        width: 34px;
        height: 34px;
        border-radius: 21px;
        top: 2px;
        right: -18px;
        cursor: pointer;

        span {
          background-color: $color;
          position: absolute;
          display: block;
          width: 30px;
          height: 4px;
          border-radius: 2px;
          top: 15px;
          left: 2px;
        }

        .one {
          rotate: 45deg;
        }

        .two {
          rotate: -45deg;
        }
      }
    }
  }
}

@media (max-height: 800px) {
  .main {
    padding-left: 130px;

    .window {
      padding: 10px 30px 20px 20px;
      border-radius: 20px;

      form {
        gap: 6px;

        label {
          .svgBox {
            height: 30px;
            width: 30px;
            top: 40px;
          }
        }

        .titleBox {
          .title {
            font-size: 32px;
          }

          svg {
            translate: 0 5px;
            scale: 0.8;
          }
        }

        label {
          font-size: 20px;

          input {
            font-size: 20px;
          }
          .input,
          select {
            width: 350px;
            height: 40px;
            font-size: 20px;
          }
        }

        .button {
          width: 200px;
          height: 44px;
          margin-top: 20px;
          border-radius: 22px;
          font-size: 20px;
        }

        .close {
          width: 28px;
          height: 28px;

          span {
            width: 22px;
            top: 12px;
            left: 3px;
          }
        }
      }
    }
  }
}
