@import "../scss/variables.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;

  .title {
    font-size: 54px;
    font-weight: 900;
    letter-spacing: 6px;
    font-family: Arial, Helvetica, sans-serif;
    color: $bgcolor;
  }
}

@media (max-height: 800px) {
  .header {
    height: 38px;

    .title {
      font-size: 38px;
    }
  }
}
