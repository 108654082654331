@import "../../scss/variables.scss";

.jspm {
  font-size: 30px;
  display: flex;
  color: $color;

  .statusWorking {
    color: #12b82b !important;
  }
  .statusNotWorking {
    color: #ff2929 !important;
  }
}

@media (max-height: 800px) {
  .jspm {
    font-size: 24px;
  }
}
