@import "../scss/variables.scss";
@import "../scss/whiteLoader.scss";
@import "../scss/loader.scss";

$width: 840px;

.wrapper {
  @include flexCenter;
  height: 100%;
}

.main {
  color: $bgcolor;
  font-size: 20px;
  height: 98%;
  @include flexCenter;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .devices {
    display: flex;
    height: 110px;
    align-items: center;
    justify-content: space-between;
    width: $width;

    .device {
      width: 410px;
      height: 100%;
      border-radius: 25px;
      background-color: $color;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 34px;
      letter-spacing: 0.1rem;
      color: $bgcolor;

      &:hover {
        cursor: pointer;
      }

      svg {
        scale: 0.5;
      }
    }

    .activeDevice {
      background-color: #0c2f65;
      box-shadow: 0 0 15px black inset;
    }
  }

  .content {
    position: relative;
    width: $width;
    height: 410px;
    border-radius: 30px;
    background-color: $color;
    overflow: hidden;

    .scanner {
      position: relative;
      width: 100%;
      height: 100%;

      .bar {
        width: 80%;
        height: 50%;
        border-radius: 40px;
        background-color: $bgcolor;
        margin: 45px auto;
        overflow: hidden;
        @include flexCenter;

        svg {
          scale: 0.85;
        }
      }

      .result {
        display: block;
        width: 80%;
        height: 60px;
        margin: 40px auto;
        border-radius: 30px;
        text-align: center;
        font-size: 36px;
        font-family: $ff;
        background-color: $bgcolor;
        color: $color;
        border: none;
        font-family: Arial, Helvetica, sans-serif;
      }
    }

    .printer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0;

      span {
        background-color: $bgcolor;
        padding: 8px 15px;
        border-radius: 25px;
        white-space: nowrap;
      }

      .inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        margin: 20px 0;
      }
    }

    .background {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      color: $bgcolor;
    }
  }
}

.loaderBox {
  color: $redButtons;
  font-size: 24px;
}

@media (max-height: 800px) {
  .main {
    font-size: 20px;
    gap: 10px;

    .devices {
      height: 80px;
      width: 740px;

      .device {
        width: 360px;
        border-radius: 20px;
        gap: 5px;
        font-size: 24px;

        svg {
          scale: 0.4;
        }
      }
    }

    .content {
      width: 740px;
      height: 320px;
      border-radius: 20px;

      .scanner {
        .bar {
          width: 80%;
          height: 60%;
          border-radius: 20px;
          margin: 25px auto;

          svg {
            scale: 0.85;
          }
        }

        .result {
          width: 80%;
          height: 40px;
          margin: 30px auto;
          border-radius: 30px;
          font-size: 26px;
        }
      }

      .printer {
        padding: 15px 0;

        span {
          padding: 8px 15px;
          border-radius: 20px;
        }

        .inputs {
          gap: 20px;
          margin: 20px 0;
        }
      }
    }
  }
}
