@import "./../scss/variables.scss";

$fileBoxActive: rgba(255, 255, 255, 0.5);

.box {
  border: 2px dashed white;
  width: 450px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 15px;
  font-size: 24px;
  color: white;
  font-family: $ff;
  letter-spacing: normal;
  cursor: pointer;
  padding: 5px;
  @include flexCenter;
  flex-wrap: wrap;

  &:hover {
    background-color: $fileBoxActive;
  }

  input {
    display: none;
  }
}

.onDrag {
  background-color: $fileBoxActive;
}
