@import "../scss/variables.scss";

.paginationBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 328px;
  height: 51px;

  div,
  input {
    background-color: $bgcolor;
    color: $color;
    height: 100%;
    border-radius: 20px;
    @include flexCenter;
    font-size: 24px;
    font-weight: 500;
    box-sizing: border-box;
  }

  .prevNext {
    width: 115px;
    user-select: none;

    &:hover {
      cursor: pointer;
    }
    &:active {
      scale: 0.97;
    }
  }

  .nonActive {
    background-color: $color;
    color: $bgcolor;
    border: 2px $bgcolor solid;

    &:hover {
      cursor: auto;
    }
    &:active {
      scale: 1;
    }
  }

  .currentPage {
    width: 67px;
    text-align: center;
    border: none;
  }
}

@media (max-height: 800px) {
  .paginationBox {
    width: 260px;
    height: 36px;

    div,
    input {
      border-radius: 14px;
      font-size: 18px;
    }

    .prevNext {
      width: 90px;
    }
  }
}
