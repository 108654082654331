@import "../../scss/variables.scss";
@import "../../scss/whiteLoader.scss";

.main {
  position: fixed;
  @include flexCenter;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding-left: 160px;
  z-index: 2;

  .window {
    width: 750px;
    background-color: $color;
    color: $bgcolor;
    position: relative;
    border-radius: 30px;
    padding: 30px 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      display: block;
      color: $bgcolor;
      width: auto;
      height: auto;
      margin-bottom: 10px;
      border: none;
      font-size: 28px !important;
    }

    .info {
      background-color: $bgcolor;
      width: 600px;
      box-sizing: border-box;
      padding: 20px 20px;
      border-radius: 25px;
      margin-bottom: 20px;

      span {
        color: $color;
      }

      p {
        display: inline-block;
        margin: 0 0 0 10px;
      }

      .pendingStatus {
        color: #cd8820;
      }

      .printingStatus {
        color: #04ae20;
      }
    }

    .disableLoaderBtn,
    button {
      color: $bgcolor;
      background-color: $redButtons;
      width: 280px;
      height: 60px;
      border-radius: 32px;
      border: none;
      font-size: 28px;
      margin-top: 20px;
      @include flexCenter;

      &:hover {
        cursor: pointer;
      }

      &:active {
        scale: 0.97;
      }
    }

    select {
      background-color: $bgcolor;
      color: $color;
      width: 350px;
      height: 40px;
      border-radius: 20px;
      border: none;
      font-size: 24px;
      padding: 0 10px;
    }

    .close {
      background-color: $bgcolor;
      position: absolute;
      width: 34px;
      height: 34px;
      border-radius: 21px;
      top: 12px;
      right: 12px;
      cursor: pointer;

      span {
        background-color: $color;
        position: absolute;
        display: block;
        width: 30px;
        height: 4px;
        border-radius: 2px;
        top: 15px;
        left: 2px;
      }

      .one {
        rotate: 45deg;
      }

      .two {
        rotate: -45deg;
      }
    }
    .errorMessage {
      color: $wrongMessageColor;
      position: absolute;
      bottom: 84px;
      font-size: 24px;
    }
  }
}

@media (max-height: 800px) {
  .main {
    padding-left: 130px;

    .window {
      width: 580px;
      border-radius: 20px;

      span {
        font-size: 24px !important;
      }

      .info {
        width: 500px;
        font-size: 24px;
      }

      .disableLoaderBtn,
      button {
        width: 240px;
        height: 50px;
        border-radius: 22px;
        font-size: 22px;
      }

      .close {
        width: 28px;
        height: 28px;

        span {
          width: 22px;
          top: 12px;
          left: 3px;
        }
      }
      .errorMessage {
        bottom: 78px;
        font-size: 20px;
      }
    }
  }
}
