@import "../scss/variables.scss";
@import "../scss/loader.scss";

.loaderBox {
  margin-top: 200px;
  color: $redButtons;
  font-size: $fsDefault;
}

.main {
  background-color: $color;
  width: 100%;
  height: 98%;
  border-radius: 25px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: start;

  .data {
    display: flex;
    flex-direction: column;
    gap: 14px;

    span {
      @include flexCenter;
      width: 100%;
      height: 100%;
      color: $color;
      font-size: $fsDefault;
      font-weight: 500;
      margin: 0 auto;
      white-space: nowrap;
      overflow: hidden;
    }

    .dataTitle {
      background-color: $bgcolor;
      width: 100%;
      border-radius: 10px;
      display: grid;
      align-items: center;
      gap: 5px;
      height: 60px;
      box-sizing: border-box;
      grid-template-columns: $gridClients;

      .inputBox {
        @include flexCenter;
        min-width: 30px;
        position: relative;

        input {
          min-width: 20px;
          border: none;
          font-family: $ff;
          height: 30px;
          color: $redButtons;
          font-size: $fsDefault;
          font-weight: 600;
          text-align: center;
          margin: 0 2px;
          padding: 0;
          box-sizing: border-box;

          &::placeholder {
            color: $color;
          }
          &::-moz-placeholder {
            color: $color;
            opacity: 1;
          }

          &:focus {
            outline: none;
          }

          .inputActive {
            color: $redButtons;
          }
        }
      }
    }

    .item {
      height: 52px;
      font-size: 40px;

      span {
        border-right: 1px solid $color;
      }
    }
  }

  @media (max-height: 1000px) {
    .data {
      gap: 6px;
      .item {
        min-height: 47px;
        max-height: 47px;
      }
    }
  }

  @media (max-height: 910px) {
    .data {
      gap: 8px;
      .item {
        min-height: 45px;
        max-height: 45px;
      }
    }
  }

  @media (max-height: 890px) {
    .data {
      gap: 6px;
      .item {
        min-height: 38px;
        max-height: 38px;
      }
    }
  }

  @media (max-width: $widthSize) {
    .data {
      .dataTitle {
        height: 45px;
        .inputBox {
          input {
            font-size: $fsSm;
          }
        }
      }
      span {
        font-size: $fsSm;
      }
    }
  }
}

@media (max-height: 800px) {
  .main {
    border-radius: 25px;
    padding: 10px 20px;
    gap: 10px;

    .data {
      gap: 5px;

      span {
        font-size: 16px;
      }

      .dataTitle {
        gap: 5px;
        height: 30px;
        min-height: 30px;

        .inputBox {
          min-width: 20px;

          input {
            min-width: 20px;
            height: 20px;
            font-size: 16px;
          }
        }
      }

      .item {
        height: 30px;
        font-size: 16px;
      }
    }
  }
}
