@import "../../scss/variables.scss";
@import "../../scss/loader.scss";

.loaderBox {
  scale: 0.7;
}

.item {
  min-height: 48px;
  width: 100%;
  display: grid;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  grid-template-columns: $gridLabels;
  border-bottom: 2px gray solid;

  span {
    text-align: center;
    font-size: $fsSm;
    font-weight: 500;
  }

  .statusPrinted {
    color: #04ae20;
  }
  .statusPending {
    color: #cd8820;
  }

  .close {
    position: relative;
    width: 42px;
    aspect-ratio: 1;
    border-radius: 21px;
    cursor: pointer;

    span {
      position: absolute;
      display: block;
      width: 30px;
      height: 4px;
      border-radius: 2px;
      background-color: $redButtons;
      top: 19px;
      left: 4px;
    }

    .one {
      rotate: 45deg;
    }

    .two {
      rotate: -45deg;
    }
  }

  .editStatus {
    width: 47px;
    aspect-ratio: 1.4;
    border-radius: 7px;
    background-color: $color;
    @include flexCenter;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
    }

    svg {
      scale: 0.8;
    }
  }

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .window {
    background-color: $color;
    color: $bgcolor;
    position: absolute;
    top: 17%;
    left: 25%;
    border-radius: 37px;
    padding: 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.itemUnacc {
  grid-template-columns: $gridUnaccLabels;
}

@media (max-height: 800px) {
  .item {
    min-height: 48px;
    width: 100%;
    gap: 5px;

    span {
      font-size: 16px;
    }

    .editStatus {
      width: 36px;
      aspect-ratio: 1.4;
      border-radius: 5px;

      svg {
        scale: 0.6;
      }
    }

    .window {
      top: 17%;
      left: 25%;
      border-radius: 37px;
      padding: 60px;
    }
  }
}
