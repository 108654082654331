$color: #0f3c81;
$bgcolor: #ffffff;
$hoverButtonCover: #b4b4b4;
$wrongMessageColor: #ff6c6c;
$wrongMessageStorkeColor: #ff4141;
$redButtons: #b91717;
$gridOrders: 4fr 8fr 10fr 10fr 7fr 8fr 6fr 180px;
$gridClients: 3fr 12fr 12fr 12fr;
$gridLabels: 3fr 6fr 4fr 10fr 6fr 6fr 2fr;
$gridUnaccLabels: 3fr 6fr 14fr 6fr 6fr 2fr;
$ff: "Montserrat", sans-serif;
$fsDefault: 22px;
$fsSm: 18px;
$widthSize: 1700px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
