@import "../scss/variables.scss";

.main {
  position: absolute;
  top: 7%;
  @include flexCenter;
  padding: 25px;
  height: 30px;
  border: 1px $color solid;
  background-color: $bgcolor;
  border-radius: 20px;
  box-shadow: 0 0 10px gray;
  animation: fade 0.2s 1 normal;
  z-index: 3;

  @keyframes fade {
    0% {
      top: 0;
    }
    100% {
      top: 7%;
    }
  }

  p {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
  .green {
    color: green !important;
  }
  .red {
    color: red !important;
  }
}

.closed {
  top: -90px;
  transition: 0.2s;
}
