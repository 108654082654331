@import "../../scss/variables.scss";

.button {
  @include flexCenter;
  gap: 20px;
  height: 72px;
  border-radius: 26px;
  color: $bgcolor;
  font-size: 24px;
  background-color: $redButtons;
  border: none;
  padding: 0 15px;
  box-sizing: border-box;
  width: 380px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    scale: 0.97;
  }
}

.disabledBtn {
  background-color: gray;
  &:hover {
    cursor: auto;
  }

  &:active {
    scale: 1;
  }
}

@media (max-height: 800px) {
  .button {
    gap: 20px;
    height: 52px;
    border-radius: 22px;
    font-size: 20px;
    padding: 0 15px;
    width: 320px;
  }
}
