@import "../../scss/variables.scss";
@import "../../scss/whiteLoader.scss";

.main {
  position: fixed;
  @include flexCenter;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding-left: 160px;
  z-index: 2;

  .window {
    background-color: $color;
    color: $bgcolor;
    position: relative;
    border-radius: 30px;
    padding: 45px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      color: $bgcolor;
      width: auto;
      height: auto;
      font-size: 30px;
      margin-bottom: 10px;
      border: none;
    }

    .info {
      background-color: $bgcolor;
      padding: 20px 40px;
      border-radius: 25px;
      margin-bottom: 40px;

      span {
        color: $color;
      }

      p {
        margin: 0 0 0 10px;
      }

      .processingStatus {
        color: #cd8820;
      }
    }

    .disableLoaderBtn,
    button {
      color: $bgcolor;
      background-color: $redButtons;
      padding: 0 20px;
      height: 56px;
      width: 250px;
      border-radius: 28px;
      border: none;
      font-size: 24px;
      margin-top: 30px;
      box-sizing: border-box;
      @include flexCenter;

      &:hover {
        cursor: pointer;
      }

      &:active {
        scale: 0.97;
      }
    }

    select {
      background-color: $bgcolor;
      color: $color;
      width: 350px;
      height: 40px;
      border-radius: 20px;
      border: none;
      font-size: 20px;
      padding: 0 10px;
    }

    .close {
      background-color: $bgcolor;
      position: absolute;
      width: 34px;
      height: 34px;
      border-radius: 21px;
      top: 15px;
      right: 15px;
      cursor: pointer;

      span {
        background-color: $color;
        position: absolute;
        display: block;
        width: 30px;
        height: 4px;
        border-radius: 2px;
        top: 15px;
        left: 2px;
      }

      .one {
        rotate: 45deg;
      }

      .two {
        rotate: -45deg;
      }
    }

    .errorMessage {
      color: $wrongMessageColor;
      position: absolute;
      bottom: 5px;
      font-size: 24px;
    }
  }
}

@media (max-height: 800px) {
  .main {
    padding-left: 130px;

    .window {
      padding: 40px;

      span {
        font-size: 24px;
        margin-bottom: 10px;
      }

      .disableLoaderBtn,
      button {
        height: 46px;
        width: 220px;
        border-radius: 20px;
        font-size: 20px;
        margin-top: 20px;
      }

      select {
        width: 300px;
      }

      .close {
        width: 28px;
        height: 28px;

        span {
          width: 22px;
          top: 13px;
          left: 3px;
        }
      }
    }
  }
}
