@import "../scss/variables.scss";

.aside {
  background-color: $color;
  min-width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  box-sizing: border-box;
  box-shadow: 0 0 10px black;

  .collapseBtn {
    position: absolute;
    top: 10px;
    left: 265px;
    border: none;
    background-color: transparent;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    @include flexCenter;
    outline: none;

    &:hover {
      cursor: pointer;
    }
  }

  .collapseBtnColl {
    transform: scale(-1, 1);
    left: 25px;
  }

  .logoBox {
    width: 290px;
    height: 140px;
    @include flexCenter;
  }

  .logoBoxColl {
    width: 97px;
    aspect-ratio: 1;
    img {
      width: 48px;
    }
  }

  a {
    text-decoration: none;
  }

  .btnBlock {
    display: flex;
    flex-direction: column;
    gap: 50px;
    translate: 0 -30px;
  }

  .navButton {
    background-color: $bgcolor;
    color: $color;
    width: 280px;
    height: 67px;
    font-size: 26px;
    font-weight: 500;
    border-radius: 37px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 20px;
    box-sizing: border-box;
    border: 2px $color solid;

    &:hover {
      cursor: pointer;
    }

    .svgBox {
      min-width: 67px;
      min-height: 67px;
      max-width: 67px;
      max-height: 67px;
      @include flexCenter;

      svg {
        scale: 0.8;
      }
    }

    div {
      @include flexCenter;
      flex-grow: 1;
    }
  }

  .navButtonActive {
    background-color: $color;
    color: $bgcolor;
    border: 2px $bgcolor solid;
  }

  .navButtonColl {
    width: 80px;
    padding: 0 6px;
    color: transparent;
    font-size: 1px;
  }

  .bottomBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .loginUserLabel {
      bottom: 100px;
      position: absolute;
      width: 200px;
      height: 38px;
      font-size: 15px;

      &:hover {
        cursor: auto;
      }
    }

    .loginUserLabelCall {
      width: 0;
      opacity: 0;
    }

    .logoutBtn {
      width: 280px;
      aspect-ratio: 4.25;
      justify-self: flex-end;
      margin: 25px 0;

      .svgBox {
        min-width: 67px;
        min-height: 67px;
        max-width: 67px;
        max-height: 67px;
        @include flexCenter;

        svg {
          scale: 0.8;
        }
      }
    }
    .logoutBtnCall {
      width: 80px;
      padding: 0 6px;
      color: transparent;
      font-size: 1px;
    }
  }
}

.asideColl {
  min-width: 100px;
  max-width: 100px;
}

@media (max-height: 800px) {
  .aside {
    min-width: 260px;
    padding-top: 40px;

    .collapseBtn {
      left: 205px;
    }

    .collapseBtnColl {
      left: 25px;
    }

    .logoBox {
      width: 230px;
      height: 110px;

      svg {
        scale: 0.8;
      }
    }

    .logoBoxColl {
      width: 97px;
      img {
        width: 48px;
      }
    }

    .btnBlock {
      gap: 20px;
      translate: 0 -30px;
    }

    .navButton {
      width: 220px;
      height: 48px;
      font-size: 20px;
      border-radius: 37px;
      padding: 0 20px;

      .svgBox {
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
      }
    }

    .navButtonColl {
      width: 66px;
      padding: 0 6px;
      font-size: 1px;
    }

    .bottomBox {
      .loginUserLabel {
        bottom: 70px;
        width: 160px;
        height: 32px;
        font-size: 13px;
      }

      .logoutBtn {
        width: 220px;
        margin: 15px 0;

        .svgBox {
          min-width: 50px;
          min-height: 50px;
          max-width: 50px;
          max-height: 50px;
        }
      }
      .logoutBtnCall {
        width: 66px;
      }
    }
  }
  .asideColl {
    min-width: 100px;
    max-width: 100px;
  }
}
