@import "../../scss/variables.scss";
@import "../../scss/loader.scss";
@import "../../scss/whiteLoader.scss";

.wrapper {
  margin: auto;

  .button {
    @include flexCenter;
    gap: 20px;
    height: 64px;
    border-radius: 26px;
    color: $bgcolor;
    font-size: 20px;
    background-color: $redButtons;
    border: none;
    padding: 0 10px;
    box-sizing: border-box;
    width: 380px;

    &:hover {
      cursor: pointer;
    }

    svg {
      scale: 0.8;
    }
  }

  .backButton {
    position: absolute;
    top: 10px;
    left: 0;
    width: 150px;
  }

  .main {
    position: relative;
    background-color: $color;
    color: $bgcolor;
    max-width: 800px;
    font-size: 20px;
    border-radius: 35px;
    padding: 25px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow: hidden;

    p {
      display: inline;
      color: $redButtons;
    }

    span {
      background-color: $bgcolor;
      color: $color;
      padding: 8px 15px;
      border-radius: 25px;
      white-space: nowrap;
    }

    .checkbox {
      position: absolute;
      width: 28px;
      aspect-ratio: 1;
      border: 3px solid $redButtons;
      border-radius: 8px;
      translate: -45px -5px;

      div {
        position: absolute;
        display: block;
        width: 21px;
        height: 4px;
        border-radius: 2px;
        background-color: $redButtons;
        top: 17px;
        left: 2px;
      }

      .one {
        width: 12px;
        rotate: 45deg;
      }

      .two {
        top: 14px;
        left: 6px;
        rotate: -45deg;
      }
    }

    .title {
      display: flex;
      justify-content: center;
      gap: 26px;

      span {
        font-weight: 600;
      }
    }

    .jspmMessage {
      font-family: $ff;
      font-size: 26px;
      letter-spacing: 0.1em;
      text-align: center;
    }

    .background {
      position: absolute;
      @include flexCenter;
      flex-direction: column;
      gap: 20px;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      color: $bgcolor;
    }
  }
}

.loaderBox {
  margin-top: 200px;
  color: $redButtons;
  font-size: 24px;
}

@media (max-height: 800px) {
  .wrapper {
    .button {
      gap: 20px;
      height: 50px;
      border-radius: 22px;
      font-size: 20px;
      width: 380px;

      svg {
        scale: 0.7;
      }
    }

    .backButton {
      width: 130px;
    }

    .main {
      max-width: 800px;
      font-size: 20px;
      border-radius: 35px;
      padding: 15px 20px;
      gap: 15px;

      span {
        padding: 6px 15px;
        border-radius: 25px;
      }

      .checkbox {
        position: absolute;
        width: 28px;
        aspect-ratio: 1;
        border: 3px solid $redButtons;
        border-radius: 8px;
        translate: -45px -5px;

        div {
          position: absolute;
          display: block;
          width: 21px;
          height: 4px;
          border-radius: 2px;
          background-color: $redButtons;
          top: 17px;
          left: 2px;
        }

        .one {
          width: 12px;
          rotate: 45deg;
        }

        .two {
          top: 14px;
          left: 6px;
          rotate: -45deg;
        }
      }

      .title {
        display: flex;
        justify-content: center;
        gap: 22px;
      }
    }
  }
}
