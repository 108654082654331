@import "../scss/variables.scss";
@import "../scss/loader.scss";

.loaderBox {
  margin-top: 200px;
  color: $redButtons;
  font-size: 24px;
}

.main {
  background-color: $color;
  width: 100%;
  height: 98%;
  border-radius: 25px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: space-between;

  .data {
    display: flex;
    flex-direction: column;
    gap: 14px;
    max-height: 92%;

    .dataTitle {
      background-color: $bgcolor;
      height: 60px;
      min-height: 60px;
      width: 100%;
      border-radius: 10px;
      display: grid;
      align-items: center;
      gap: 5px;
      box-sizing: border-box;
      grid-template-columns: $gridOrders;
    }

    .inputBox {
      @include flexCenter;
      min-width: 30px;
      position: relative;

      input {
        min-width: 20px;
        border: none;
        font-family: $ff;
        height: 24px;
        min-width: 30px;
        color: $redButtons;
        font-size: $fsDefault;
        font-weight: 600;
        text-align: center;
        margin: 0 2px;
        padding: 0;
        background-color: transparent;

        &::placeholder {
          color: $color;
        }
        &::-moz-placeholder {
          color: $color;
          opacity: 1;
        }

        &:focus {
          outline: none;
        }

        .inputActive {
          color: $redButtons;
        }
      }
    }

    .item {
      height: 50px;
    }
  }

  @media (max-height: 1000px) {
    .data {
      gap: 6px;
    }
  }

  @media (max-height: 910px) {
    .data {
      gap: 8px;
    }
  }

  @media (max-height: 850px) {
    .data {
      gap: 6px;
    }
  }

  @media (max-width: $widthSize) {
    .data {
      .inputBox {
        input {
          font-size: $fsSm;
          translate: -7px 0;
        }
      }
      .dataTitle {
        grid-template-columns: 4fr 8fr 10fr 10fr 7fr 8fr 6fr 140px;
        height: 45px;
        min-height: 45px;
      }
    }
  }
}

@media (max-height: 800px) {
  .main {
    padding: 10px 20px;
    gap: 10px;

    .data {
      gap: 5px;

      .dataTitle {
        width: 100%;
        border-radius: 10px;
        gap: 5px;
        height: 36px;
        min-height: 36px;
      }

      .inputBox {
        input {
          min-width: 20px;
          height: 20px;
          min-width: 30px;
          font-size: 16px;
        }
      }

      .item {
        height: 50px;
      }
    }
  }
}
